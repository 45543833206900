import * as React from "react"

export default function RobotsTxt({serverData}) {
	return serverData.message
}

export async function getServerData() {
	return {
		props: {
			message: `
			User-agent: *

			Disallow: /
			`
		}
	}
}
